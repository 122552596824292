import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const CounterPage = ({ pageContext }) => (
  <Layout>
    <SEO title="Loket" />
    <h1>
      {pageContext.type} {pageContext.counter}
    </h1>
    <p>Antrian sekarang: 10</p>
    <button>Mulai</button> {/* start queue */}
    <button>Selanjutnya</button> {/* next queue */}
    <button>Lewati</button> {/* skip queue */}
    <button>Selesai</button> {/* finish/reset queue */}
  </Layout>
);

export default CounterPage;
